<template>
  <div class="tw-flex tw-flex-col tw-gap-3">
    <div class="tw-flex tw-items-center">
      <div class="tw-grow-0 tw-pr-4 tw-text-3xl tw-font-bold tw-tracking-wide">
        Commodities Macro
      </div>

      <div class="tw-grow tw-h-1 tw-bg-black" />
    </div>

    <div class="tw-grid tw-grid-cols-4 tw-divide-x tw-divide-black">
      <div
        v-for="(topic, index) in topics"
        :key="index"
        class="tw-p-4 tw-text-xl tw-font-bold tw-leading-6"
      >
        {{ topic.title }}
      </div>
    </div>

    <div class="tw-w-full tw-h-1 tw-bg-black" />
  </div>
</template>

<script>
export default {
  data () {
    return {
      topics: [
        {
          title: 'Commodities” is up 10% over the last month, almost all commodities has gone up in this period.'
        },
        {
          title: 'Commodities is most popular in Asia - mainly in China.'
        },
        {
          title: 'Commodities has a standard deviation of 3 over the last month'
        },
        {
          title: 'The coverage of “Commodities” is up 2% in financial media over the last month'
        }
      ]
    }
  }
}
</script>
